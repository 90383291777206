(function( $ ){

    $(".datetime-picker").flatpickr({ 
        enableTime: true,
        dateFormat: "d/m/Y H:i",
        locale: "pt",
        theme: "material-green"
    });

    $(".date-picker").flatpickr({ 
        dateFormat: "d/m/Y",
        locale: "pt",
        theme: "material-green"
    });

    if($('.project-authors-item').length) {
        var author_fields = $('.project-authors-item')[0].outerHTML;
    }
    if($('.project-mentors-item').length) {
        var mentor_fields = $('.project-mentors-item')[0].outerHTML;
    }

    $(".alert").alert();
    $('.phone-mask').mask('(00) 0000-00009');
    $('.issn').mask('0000-000Z', {'translation': {Z: {pattern: /[0-9X]/}}});

    $('.btn-check').click(function(e) {
        var $form = $(this).closest('form');
        e.preventDefault();
        bootbox.confirm("Tem certeza que deseja realizar essa ação?", function(result) {
            if(result) {
                $form.trigger('submit');
            }
        });
    });

    $('.btn-destroy').click(function(e) {
        var $form = $(this).closest('form');
        e.preventDefault();
        bootbox.confirm("Tem certeza que deseja deletar esse registro?", function(result) {
            if(result) {
                $form.trigger('submit');
            }
        });
    });

    $('#project_status_id').change(function(e){
        $('#project_step_reason_container').removeClass('d-none');
        $('#project_step_reason_container input').attr('required', true);
        $('#project-edit-form').parsley();
    });

    $('.btn-bulk').click(function(e) {
        var $form = $(this).closest('form');
        e.preventDefault();
        bootbox.confirm("Tem certeza que deseja realizar essa ação?", function(result) {
            if(result) {
                $form.trigger('submit');
            }
        });
    });

    $('[data-toggle="tooltip"]').tooltip({
        container: 'body'
    });

    $('[data-toggle="popover"]').popover()

    $('#forgot-link').click(function(e){
        e.preventDefault();
        bootbox.dialog({ 
            message: $('#forgot-wrap').html(), 
            closeButton: true
        }).init(function() { 
            $('.form-forgot').parsley();
            $('.forgoted-email').val($('#email').val());
        });
        return false;
    });

    $('.alert-modal').each(function(e) {
        var box = bootbox.alert({ 
            message: $(this).html()
        });
        setTimeout(function() {
            box.modal('hide');
        }, 1800);
    });

    $('.project-authors-list').on('click', '.remove-item', function() {
        $(this).parent('.project-authors-item').remove();
    });

    $('.project-mentors-list').on('click', '.remove-item', function() {
        $(this).parent('.project-mentors-item').remove();
    });

    var count_authors = 1;
    $('#project-add-author').click(function(e){
        e.preventDefault();
        fields = author_fields.replace(/project\[author\]\[0\]/g, 'project[author]['+(count_authors)+']');
        $('.project-authors-list').append(fields);
        $('.project-authors-list .project-authors-item').last()
            .addClass('animated fadeIn')
            .append('<i class="fas fa-times remove-item"></i>');
        $('#new-project-form').parsley().reset();
        $('.phone-mask').unmask().mask('(00) 0000-00009');
        count_authors++;
    });

    var count_mentors = 1;
    $('#project-add-mentor').click(function(e){
        e.preventDefault();
        fields = mentor_fields.replace(/project\[mentor\]\[0\]/g, 'project[mentor]['+(count_mentors)+']');
        $('.project-mentors-list').append(fields);
        $('.project-mentors-list .project-mentors-item').last()
            .addClass('animated fadeIn')
            .append('<i class="fas fa-times remove-item"></i>');
        $('#new-project-form').parsley().reset();
        count_mentors++;
    });

    $('#project-banner').change(function(e){
        var fake_element = $(this).data('fake-input');
        $(fake_element).html('<i class="fas fa-paperclip fa-fw"></i> ' + e.target.files[0].name);
    });

    $('#project-file').change(function(e){
        var fake_element = $(this).data('fake-input');
        $(fake_element).html('<i class="fas fa-paperclip fa-fw"></i> ' + e.target.files[0].name);
    });

    $.listen('parsley:field:error', function(ParsleyField) {
        ParsleyField.$element.prev('.fake-wrapper').addClass('parsley-error');
    });

    $.listen('parsley:field:success', function(ParsleyField) {
        ParsleyField.$element.prev('.fake-wrapper').removeClass('parsley-error');
    });

    $('.btn-author-delete').click(function(e){
        $($(this).data('author-container')).remove();
        e.preventDefault();
    });

    $('.btn-mentor-delete').click(function(e){
        $($(this).data('mentor-container')).remove();
        e.preventDefault();
    });

    $('#user_role').change(function() {
        $('.role-container').removeClass('show');
        $('.' + $(this).val() + '-container').addClass('show');
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('input.check-all').change(function(e) {
        $('input[type=checkbox].checkbox-itens').prop('checked', $(this).prop("checked"));
    });

    $('.bulk-action-form').submit(function(e) {
        $form = $(this);
        $('input[type=checkbox].checkbox-itens').each(function(index, element){
            if($(this).prop("checked")) {
                $form.append('<input type="hidden" name="items['+index+'][id]" value="'+$(this).val()+'" />')
            }
        });
    });

    $('.bulk-action-select').change(function() {
        var selected = $(this).find('option:selected');
        var action = selected.data('action'); 
        if($(this).val() == 'PUT' && action == 'change_status') {
            $('.bulk-action-status').removeClass('d-none');
            $('.hiddden_print_val').val(false);
        } else if($(this).val() == 'DELETE' && action == 'delete') {
            $('.bulk-action-status').addClass('d-none');
            $('.hiddden_print_val').val(false);
        } else if($(this).val() == 'POST' && action == 'print') {
            $('.bulk-action-status').addClass('d-none');
            $('.hiddden_print_val').val(true);
        } else {
            $('.bulk-action-status').addClass('d-none');
            $('.hiddden_print_val').val(false);
        }
    });

    $('#reviewer-course-select').change(function() {
        $(this).parent('form').submit();
    });

    $('#project-select-area').change(function(e) {
        var id = $(this).val();
        $.getJSON('/project/select-area/'+id, function(data){
            var elm = $('#project-select-course');
            elm.html('');
            elm.append('<option value="" >Selecione um curso</option>')
            if(elm.length > 0) {
                $.each(data, function(i, item) {
                    elm.append('<option value="'+item.id+'" >'+item.name+'</option>');
                });
            }
        });
    });

    $('#filter-banner-areas').change(function(e) {
        var id = $(this).val();
        $.getJSON('/project/select-area/'+id, function(data){
            var elm = $('#filter-banner-courses');
            elm.html('');
            elm.append('<option value="" >Selecione um curso</option>')
            if(elm.length > 0) {
                $.each(data, function(i, item) {
                    elm.append('<option value="'+item.id+'" >'+item.name+'</option>');
                });
            }
        });
    });

    $('#filter-project-areas').change(function(e) {
        var id = $(this).val();
        $.getJSON('/project/select-area/'+id, function(data){
            var elm = $('#filter-project-courses');
            elm.html('');
            elm.append('<option value="" >Selecione um curso</option>')
            if(elm.length > 0) {
                $.each(data, function(i, item) {
                    elm.append('<option value="'+item.id+'" >'+item.name+'</option>');
                });
            }
        });
    });

    $('.cep-auto').change(function(e) {
        var cep = $(this).val().replace('-', '');
        $.getJSON('https://viacep.com.br/ws/'+cep+'/json/', function(data){
            if(!data.erro) {
                $('.address-auto').val(data.logradouro);
                $('.city-auto').val(data.localidade);
                $('.neighborhood-auto').val(data.bairro);
                $('.state-auto').val(data.uf);
                $('.number-auto').val('');
                $('.complement-auto').val('');
            }
        });
    });

    if($('.quill-editor').length) {
        var quills = [];
        $('.quill-editor').each(function(item) {
            quills[item] = new Quill('#' + $(this).prop('id'), {
                theme: 'snow'
            });
        });
    }

    $('.quill-form').submit(function(e) {
        $('.have-quill').each(function(item) {
            var quill_id = $(this).data('quill'),
                container = document.querySelector(quill_id),
                quill_el = new Quill(container);
            $(this).val(quill_el.root.innerHTML);
        });
    });

    $('#dataTableProjectsDashboard')
        .add('#dataTableStudentsDashboard')
        .add('#dataTablePagseguroDashboard')
        .add('#dataTableQueuedIssuancesDashboard')
        .add('#dataTableQueuedEmailsDashboard')
        .add('#dataTableTrashUsers')
        .add('#dataTableTrashProjects')
        .add('#dataTableTrashStatus')
        .add('#dataTableTrashAreas')
        .add('#dataTableTrashCertificates')
        .add('#dataTableTrashCourses')
        .add('#dataTableTrashEvents')
        .DataTable({
            language: {
                url: '/js/pt-BR.json'
            },
            lengthChange: false,
            pageLength: 5,
            searching: false,
            order: [[ 0, "desc" ]]
        });


    $('#autocomplete').autocomplete({
        serviceUrl: '/admin/dashboard/autocomplete/search',
        width: 400,
        maxHeight: 400,
        onSelect: function (suggestion) {
            window.location.href = suggestion.data.url;
        },
        formatResult: function(suggestion, currentValue) {

            $icon = suggestion.data.category == 'Projetos' ? 'fa-copy' : 'fa-user';

            return "<div class=\"row no-gutters p-2 align-items-center\">" +
                "<div class=\"pl-2 col-1 text-center\">"+
                    "<i class=\"fas " + $icon + " fa-1x text-gray-300\"></i>" +
                "</div>" +
                "<div class=\"pl-3 col-11\">" +
                    "<div class=\"small text-truncate\">" + suggestion.value +"</div>" +
                    "<div class=\"small text-gray-500\">" + suggestion.data.category + "</div>" +
                "</div>" +
            "</div>";

        }
    });

    $('.form-signup').on('keydown.autocomplete','.autocomplete-course', function(){
        $(this).autocomplete({
            serviceUrl: '/student/autocomplete/courses',
            maxHeight: 400,
            onSelect: function (suggestion) {
                $(this).val(suggestion.data);
            }
        });
    });

    $('.form-change-data').on('keydown.autocomplete','.autocomplete-course', function(){
        $(this).autocomplete({
            serviceUrl: '/student/autocomplete/courses',
            maxHeight: 400,
            onSelect: function (suggestion) {
                $(this).val(suggestion.data);
            }
        });
    });
    

    $('.project-mentors-list').on('keydown.autocomplete','.autocomplete-mentor', function(){
        $(this).autocomplete({
            serviceUrl: '/project/autocomplete/ideau-mentors',
            maxHeight: 400,
            onSelect: function (suggestion) {
                $(this).parents('.project-mentor-data-input').find('.autocomplete-email').val(suggestion.data);
            }
        });
    });

    $('.hamb , .close-ico').click(function(){
        $(this).toggleClass('active');
        $('.menu-resp').toggleClass('active');
    });

    $('.form-autocomplete').submit(function(e){
        e.preventDefault();
        return false;
    });

    $('#review-project-form').on("keypress focusout", "#project_review_grade_banner, #project_review_grade_presentation", function() {
        if(parseFloat($(this).val()) > 10.0) {
            $(this).val(10.0);
        }
    });

    window.Parsley
        .addValidator('filetypes', {
            requirementType: 'string',
            validateString: function (value, requirement, parsleyInstance) {

                var file = parsleyInstance.$element[0].files;

                if (file.length == 0) {
                    return true;
                }

                var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
                return allowedMimeTypes.indexOf(file[0].type) !== -1;

            },
            messages: {
                'en': 'File mime type not allowed',
                'pt-br': 'O tipo de arquivo não é permitido'
            }
        });
        
    window.Parsley
        .addValidator('fullname', {
            requirementType: 'string',
            validateString: function (value, requirement, parsleyInstance) {
                return /^(?![ ])(?!.*[ ]{2})((?:e|da|do|das|dos|de|d'|D'|la|las|el|los)\s*?|(?:[A-Z][^\s]*\s*?)(?!.*[ ]$))+$/gmu.test(value);
            },
            messages: {
                'en': 'Write the full name.',
                'pt-br': 'Escreva o nome completo.'
            }
        });        


    window.Parsley
        .addValidator('realphone', {
            requirementType: 'string',
            validateString: function (value, requirement, parsleyInstance) {
                telefone = value.replace(/\D/g,'');
                if(!(telefone.length >= 10 && telefone.length <= 11)) return false;
                if(telefone.length == 11 && parseInt(telefone.substring(2, 3)) != 9) return false;
                for(var n = 0; n < 10; n++){
                    if(telefone == new Array(11).join(n) || telefone == new Array(12).join(n)) return false;
                }
                var codigosDDD = [
                    11, 12, 13, 14, 15, 16, 17, 18, 19,
                    21, 22, 24, 27, 28, 31, 32, 33, 34,
                    35, 37, 38, 41, 42, 43, 44, 45, 46,
                    47, 48, 49, 51, 53, 54, 55, 61, 62,
                    64, 63, 65, 66, 67, 68, 69, 71, 73,
                    74, 75, 77, 79, 81, 82, 83, 84, 85,
                    86, 87, 88, 89, 91, 92, 93, 94, 95,
                    96, 97, 98, 99
                ];
                if(codigosDDD.indexOf(parseInt(telefone.substring(0, 2))) == -1) return false;
                if (telefone.length == 10 && [2, 3, 4, 5, 7].indexOf(parseInt(telefone.substring(2, 3))) == -1) return false;
                return true;
            },
            messages: {
                'en': 'Write a correct phone.',
                'pt-br': 'Escreva um telefone correto.'
            }
        });  

    window.Parsley
        .addValidator('cpf', {
            requirementType: 'string',
            validateString: function (value, requirement, parsleyInstance) {
                value = $.trim(value);

                value = value.replace('.','');
                value = value.replace('.','');
                cpf = value.replace('-','');
                while(cpf.length < 11) cpf = "0"+ cpf;
                var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
                var a = [];
                var b = new Number;
                var c = 11;
                for (i=0; i<11; i++){
                    a[i] = cpf.charAt(i);
                    if (i < 9) b += (a[i] * --c);
                }
                if ((x = b % 11) < 2) { a[9] = 0 } else { a[9] = 11-x }
                b = 0;
                c = 11;
                for (y=0; y<10; y++) b += (a[y] * c--);
                if ((x = b % 11) < 2) { a[10] = 0; } else { a[10] = 11-x; }

                var result = true;
                if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10]) || cpf.match(expReg)) result = false;

                return result;
            },
            messages: {
                'en': 'Invalid Document.',
                'pt-br': 'Escreva um cpf válido.'
            },
        });     

})( jQuery );