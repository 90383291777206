(function( $ ){
    if(document.getElementById('project-areas-chart')) {
        var ctx = document.getElementById('project-areas-chart').getContext('2d');
        var url = new URL(window.location.href);
        var interval = url.searchParams.get("interval") != null ? url.searchParams.get("interval") : 7;
        $.getJSON('/admin/dashboard/chart/project-areas?interval=' + interval, function(chart_data){
            var chart = new Chart(ctx, {
                type: 'doughnut',
                data: chart_data
            });
        });
    }
})( jQuery );